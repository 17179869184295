export const getCards = () => {
  return [
    { id: 1, name: 'Bonus Kart', 'image': 'bonus.png', className: 'text-[rgb(0,105,63)] bg-[rgba(0,105,63,.1)]', height: 'h-[18px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      9,
      12
    ] },
    { id: 2, name: 'Axess Kart', 'image': 'axess.png', className: 'text-[rgb(0,0,0)] bg-[rgba(0,0,0,.1)]', height: 'h-[22px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      9,
      12
    ] },
    { id: 3, name: 'Maximum Kart', 'image': 'maximum.png', className: 'text-[rgb(236,0,140)] bg-[rgba(236,0,140,.1)]', height: 'h-[24px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      9,
      12
    ] },
    { id: 4, name: 'CardFinans', 'image': 'cardfinans.png', className: 'text-[rgb(0,86,162)] bg-[rgba(0,86,162,.1)]', height: 'h-[19px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      9,
      12
    ] },
    { id: 5, name: 'Paraf Kart', 'image': 'paraf.png', className: 'text-[rgb(0,178,221)] bg-[rgba(0,178,221,.1)]', height: 'h-[34px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      9,
      12
    ] },
    { id: 6, name: 'VakıfBank', 'image': 'vakifbank.png', className: 'text-[rgb(251,196,81)] bg-[rgba(251,196,81,.1)]', height: 'h-[24px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      12
    ] },
    { id: 7 , name: 'Akbank', 'image': 'akbank.png', className: 'text-[rgb(230,58,54)] bg-[rgba(230,58,54,.1)]', height: 'h-[24px]', installments: 12, instList: [
      1,
      2,
      3,
      6,
      12
    ] },
    { id: 8, name: 'Yapı ve Kredi Bankası', 'image': 'yapikredi.png', className: 'text-[rgb(18,76,140)] bg-[rgba(18,76,140,.1)]', height: 'h-[24px]', installments: 2, instList: [ 1, 2 ] },
  ];
};

export const getPackageDevices = (device_combination?: string) => {
  return [
    {id: 1, title: '1 adet bilgisayar ve 1 cep telefonu', image: '/assets/images/temp/help-product-block-1.png', active: true, emoji: [ '/assets/images/global/emoji/thumbs-up.png', '/assets/images/global/emoji/cross.png']},
    {id: 2, title: '1 adet tablet ve 1 cep telefonu', image: '/assets/images/temp/help-product-block-2.png', active: true, emoji: [ '/assets/images/global/emoji/thumbs-up.png', '/assets/images/global/emoji/cross.png']},
    {id: 3, title: '1 adet bilgisayar ve 1 tablet', image: '/assets/images/temp/help-product-block-3.png', active: device_combination === 'all' && false, emoji: [ '/assets/images/global/emoji/thumbs-up.png', '/assets/images/global/emoji/cross.png']},
  ];
};